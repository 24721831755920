.buttons {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
  width: 100px;
  margin-right: 2px;
}
.buttonsrow {
  display: flex;
  justify-content: space-between;
}
.buttons2 {
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
  width: 100px;
  margin-right: 2px;
}
.main {
  background-color: darkseagreen;
  padding: 10px;
}
.react-datepicker__close-icon {
  min-width: 0px;
  height: 0px;
}
.filterrow {
  display: flex;
  justify-content: space-between;
}
.col-3 {
  width: 25%;
  text-align: right;
}
