#mapboxcompany {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#mapboxcompany td,
#mapboxcompany th {
  border: 1px solid #ddd;
  padding: 8px;
}

#mapboxcompany th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  white-space: nowrap;
}

#mapboxcompany tr:hover {
  background-color: #ddd;
}

#mapboxcompany tr:nth-child(even) {
  background-color: #f2f2f2;
}

#mapboxcompany th:nth-child(1) {
  text-align: left;
  width: 60px;
}
#mapboxcompany th:nth-child(2) {
  text-align: left;
  width: 60px;
}

#mapboxcompany th:nth-child(2),
#mapboxcompany th:nth-child(3),
#mapboxcompany th:nth-child(4) {
  text-align: left;
}

#mapboxcompany td:nth-child(1) {
  text-align: right;
}

#mapboxcompany td:nth-child(1) a {
  text-decoration: none;
}

#mapboxcompany td:nth-child(1) a:hover {
  text-decoration: none;
}

#mapboxcompany td:nth-child(2),
#mapboxcompany td:nth-child(3),
#mapboxcompany td:nth-child(4) {
  text-align: left;
  width: auto;
}

#mapboxcompany td:nth-child(5) {
  text-align: center;
}

#mapboxcompany td:nth-child(6) {
  text-align: center;
}

#mapboxcompany td:nth-child(7) {
  text-align: center;
}

#mapboxcompany td:nth-child(8) {
  text-align: left;
}
