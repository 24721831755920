#partnerreport {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#partnerreport td,
#partnerreport th {
  border: 1px solid #ddd;
  padding: 8px;
}

#partnerreport th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  white-space: nowrap;
}

#partnerreport tr:hover {
  background-color: #ddd;
}

#partnerreport tr:nth-child(even) {
  background-color: #f2f2f2;
}
#partnerreport th {
  text-align: left;
}
#partnerreport td:nth-child(1),
#partnerreport td:nth-child(2),
#partnerreport td:nth-child(4),
#partnerreport td:nth-child(7) {
  text-align: left;
  width: 60px;
}
