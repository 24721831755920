#boxes {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#boxes td,
#boxes th {
  border: 1px solid #ddd;
  padding: 8px;
}

#boxes th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}

#boxes th:nth-child(1) {
  text-align: left;
  width: 60px;
}

#boxes th:nth-child(2) {
  text-align: left;
  width: auto;
}

#boxes th:nth-child(3) {
  text-align: left;
  width: auto;
}

#boxes tr:hover {
  background-color: #ddd;
}

#boxes tr:nth-child(even) {
  background-color: #f2f2f2;
}

#boxes td:nth-child(1) {
  text-align: right;
}

#boxes td:nth-child(1) a {
  text-decoration: none;
}

#boxes td:nth-child(1) a:hover {
  text-decoration: none;
}

#boxes th:nth-child(4) {
  text-align: left;
  width: auto;
}
#boxes td:nth-child(5) {
  text-align: center;
  width: 125px;
}

#boxes td:nth-child(6) {
  text-align: center;
  width: 125px;
}

#boxes td:nth-child(7) {
  text-align: left;
  width: 125px;
}

.modal-box {
  width: 350px;
  height: 100%;
  padding: 10px;
}
