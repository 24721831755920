#merchantreport {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#merchantreport td,
#merchantreport th {
  border: 1px solid #ddd;
  padding: 8px;
}

#merchantreport th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  white-space: nowrap;
}

#merchantreport tr:hover {
  background-color: #ddd;
}

#merchantreport tr:nth-child(even) {
  background-color: #f2f2f2;
}
#merchantreport th {
  text-align: left;
}
#merchantreport td:nth-child(1),
#merchantreport td:nth-child(2),
#merchantreport td:nth-child(4),
#merchantreport td:nth-child(7) {
  text-align: left;
  width: 60px;
}

#modal {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#modal td,
#modal th {
  border: 1px solid #ddd;
  padding: 8px;
}

#modal th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  white-space: nowrap;
}

#modal tr:hover {
  background-color: #ddd;
}

#modal tr:nth-child(even) {
  background-color: #f2f2f2;
}
#modal td:nth-child(1),
#modal td:nth-child(2) {
  text-align: center;
  width: auto;
}
#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}
