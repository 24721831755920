.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.leftMenu {
  height: 100%;
}

.menuColumn {
  background-color: #f1f1f1;
  height: 100%;
  color: #ffffff;
  float: left;
  width: 12%;
  margin: 0px;
}
.workColumn {
  height: 100%;
  float: left;
  width: 87%;
}
.workFullColumn {
  height: 100%;
  float: left;
  width: 100%;
}

.leftMenu:after {
  content: "";
  display: table;
  clear: both;
}
